import type { WritableDraft } from 'immer/dist/internal';
import React, { type RefObject } from 'react';
import type PagerView from 'react-native-pager-view';
import { type StoreApi, create } from 'zustand';
import { createContext } from 'zustand-utils';
import { immer } from 'zustand/middleware/immer';

import { ChatUnitType } from '../../../API';
import { generateConversationUsersBinaryMap } from '../functions/generateConversationUsersBinaryMap';

import { useConversationStore } from './useConversationStore';
import { useMessageStore } from './useMessageStore';

import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import useUserStore from '@/domain/user/state/useUserStore';
import { getConversationIdFromUserIds } from '@/services/datastore/conversation/getConversationIdFromUserIds';
import { getAllConversationId } from '../functions/getAllConversationId'; // #my-change: Added import for getAllConversationId
import { logger } from '@/services/logger/logger';
import type { ChatMessage, ChatUnit, ChatUnitUser } from '@/services/types';
import type { SharedValue } from 'react-native-reanimated';
import type { CustomMsg } from 'src/domain/chatUnit/components/view/types.ts';

type ActiveConversationState = {
  activeConversationIds: string[];
  activeTableId?: string;
  conversationUserIds: Map<string, string[]>;
  userConversationIds: Map<string[], string>;
  pendingConvoId?: string;
  pendingConvoHasMessages: boolean;
  userIdIndexMap: Map<string, number>;
  webActiveConversationId?: string;
  selectedMessage?: ChatMessage;
  query?: string;
  pagerRefId: string | null; // This to to pass pager container ref to dotbar.tsx so we can change conversations when clicking dotbars
  replyToMessage?: CustomMsg;
};

type ActiveConversationActions = {
  addConversationToActiveConversations: (
    chatUnitId: string,
    conversationId: string,
  ) => void;
  clearState: () => void;
  generateConversationUserMappings: (
    chatUnitId: string,
    chatUser?: ChatUnitUser,
  ) => void;
  getActiveChatId: () => string | undefined;
  getActiveChatUsers: () => ChatUnitUser[];
  setActiveChatId: (tableId?: string) => void;
  setPendingConvoId: (conversationId: string) => void;
  setWebActiveConversationId: (id?: string) => void;
  setSelectedMessage: (message: ChatMessage) => void;
  clearSelectedMessage: () => void;
  setQuery: (query: string) => void;
  clearQuery: () => void;
  getOrCreateConversationId: (userIds: string[]) => Promise<string>;
  getChatUnitUserIds: () => string[];
  setPagerRef: (ref: RefObject<PagerView> | null) => void;
  getPagerRef: () => RefObject<PagerView> | null;
  markPendingConvoHasMessages: () => void;
  handleArrowNavigation: (
    direction: 'left' | 'right',
    offset: SharedValue<number>,
    pagerRef: RefObject<PagerView>,
  ) => void;
  setReplyToMessage: (message?: CustomMsg) => void;
  clearReplyToMessage: () => void;
};

const newState: ActiveConversationState = {
  activeConversationIds: [],
  activeTableId: undefined,
  conversationUserIds: new Map(),
  userConversationIds: new Map(),
  pendingConvoId: undefined,
  pendingConvoHasMessages: false,
  userIdIndexMap: new Map(),
  selectedMessage: undefined,
  query: undefined,
  pagerRefId: null,
};

const pagerRefs: Map<string, RefObject<PagerView>> = new Map();

const generateConversationMappings = (
  state: WritableDraft<ActiveConversationState & ActiveConversationActions>,
  chatId: string,
  chatUnit: ChatUnit,
  newChatUnitUser?: ChatUnitUser,
) => {
  const chatUsers = state.getActiveChatUsers();
  if (!chatUsers.length) {
    logger.warn(
      'generateConversationMappingsWarning::noChatUsers',
      'could not find chatUsers for chatId',
      chatId,
    );
    return state;
  }

  const activeUsers = chatUsers.filter(user => user.status !== 'ARCHIVED');

  if (
    newChatUnitUser &&
    !activeUsers.find(u => u.chatUnitUserId === newChatUnitUser.chatUnitUserId)
  ) {
    activeUsers.push(newChatUnitUser);
  }

  const currentUserId = useUserStore.getState().user?.id;
  if (!currentUserId) {
    logger.warn(
      'generateConversationMappings::currentUserNotFound',
      'could not find currentUserId',
    );
    return state;
  }

  //  Add logic for ChatUnitType.ROOM
  if (chatUnit.type === ChatUnitType.ROOM) {
    // setup conversation state for ROOM

    const allConversationId = getAllConversationId(chatId);
    const youConversationId = getConversationIdFromUserIds([currentUserId]);

    state.activeConversationIds = [allConversationId, youConversationId];

    state.conversationUserIds.set(
      allConversationId,
      activeUsers.map(u => u.id),
    );
    state.conversationUserIds.set(youConversationId, [currentUserId]);

    return state;
  }

  const { userConversationIds, conversationUserIds, userIdIndexMap } =
    generateConversationUsersBinaryMap(chatId, activeUsers);

  state.conversationUserIds = conversationUserIds;
  state.userIdIndexMap = userIdIndexMap;
  state.userConversationIds = userConversationIds;

  const existingConversations =
    useConversationStore
      .getState()
      .getNonArchivedConversationsByTableId(chatId)
      .map(c => c.conversationId) ?? [];

  if (existingConversations.length === 0) {
    // No existing conversations, create default ones
    const allUsersConversationId = getConversationIdFromUserIds(
      activeUsers.map(u => u.id),
    );

    const currentUserConversationId = getConversationIdFromUserIds([
      currentUserId,
    ]);

    state.activeConversationIds = [
      allUsersConversationId,
      currentUserConversationId,
    ];

    state.conversationUserIds.set(
      allUsersConversationId,
      activeUsers.map(u => u.id),
    );
    state.conversationUserIds.set(currentUserConversationId, [currentUserId]);
  } else {
    state.activeConversationIds = existingConversations;

    // append You conversation if not present
    const youConversationId = getConversationIdFromUserIds([currentUserId]);
    if (
      youConversationId &&
      !state.activeConversationIds.includes(youConversationId)
    ) {
      state.activeConversationIds.push(youConversationId);
      state.conversationUserIds.set(youConversationId, [currentUserId]);
      state.userConversationIds.set([currentUserId], youConversationId);
    }

    const latestMessageForChatUnit = useMessageStore
      .getState()
      .getMostRecentMessagesFromChatUnits([chatId]);

    if (!latestMessageForChatUnit || !latestMessageForChatUnit[chatId]) {
      // prepend conversation with all current users (not All) to active conversations
      if (activeUsers) {
        const targetConversationId = getConversationIdFromUserIds(
          activeUsers.map(u => u.id),
        );

        if (
          targetConversationId &&
          !state.activeConversationIds.includes(targetConversationId)
        ) {
          state.activeConversationIds.unshift(targetConversationId);
        }
      }
    }
  }

  return state;
};

const {
  Provider,
  useStore: useActiveConversationStore,
  useStoreApi: useActiveConversationStoreApi,
} = createContext<
  StoreApi<ActiveConversationState & ActiveConversationActions>
>();

const createActiveConversationStore = () =>
  create<ActiveConversationState & ActiveConversationActions>()(
    immer((set, get) => ({
      ...newState,
      clearState: () => {
        set(newState);
      },

      addConversationToActiveConversations: (
        chatUnitId: string,
        conversationId: string,
      ) => {
        set(state => {
          if (chatUnitId !== state.activeTableId) {
            return state;
          }

          if (!conversationId) {
            logger.warn(
              'addConversationToActiveConversations::emptyConversationId',
            );
            return state;
          }

          if (state.activeConversationIds.includes(conversationId)) {
            return state;
          }

          const chatUnit = useTableStore.getState().getTable(chatUnitId);

          if (!chatUnit) {
            logger.error(
              'addConversationToActiveConversations::chatUnitNotFound',
              chatUnitId,
            );

            return state;
          }

          // #Handle ROOM type
          if (chatUnit.type === ChatUnitType.ROOM) {
            const allConversationId = getAllConversationId(chatUnitId);
            if (conversationId === allConversationId) {
              state.activeConversationIds.push(conversationId);
            } else {
              logger.warn(
                'addConversationToActiveConversations::invalidConversationIdForRoom',
                conversationId,
              );
            }
            return state;
          }
          // end

          // Get all chat users for the current chat unit
          const chatUsers = useTableUsersStore
            .getState()
            .getTableUsersForTable(chatUnitId);

          // Generate conversation mappings
          const { userConversationIds, conversationUserIds } =
            generateConversationUsersBinaryMap(chatUnitId, chatUsers);

          // Update state with new mappings
          state.userConversationIds = userConversationIds;
          state.conversationUserIds = conversationUserIds;

          // Add the new conversation to active conversations
          state.activeConversationIds.push(conversationId);

          if (state.pendingConvoId === conversationId) {
            state.pendingConvoId = undefined;
            state.pendingConvoHasMessages = false;
          }

          return state;
        });
      },

      generateConversationUserMappings: (
        chatUnitId: string,
        chatUser?: ChatUnitUser,
      ) => {
        set(state => {
          if (chatUnitId !== state.activeTableId) {
            return;
          }

          const chatUnit = useTableStore.getState().getTable(chatUnitId);
          if (!chatUnit) {
            logger.error(
              'generateConversationUserMappings::chatUnitNotFound',
              chatUnitId,
            );
            return;
          }

          return generateConversationMappings(
            state,
            chatUnitId,
            chatUnit,
            chatUser,
          );
        });
      },
      getActiveChatId: () => {
        return get().activeTableId;
      },
      getActiveChatUsers: () => {
        const chatUsers = useTableUsersStore
          .getState()
          .getTableUsersForTable(get().activeTableId ?? '');

        const currentUserId = useUserStore.getState().user?.id;

        if (currentUserId) {
          const currentUserIndex = chatUsers.findIndex(
            u => u.id === currentUserId,
          );
          if (currentUserIndex !== -1) {
            const currentUser = chatUsers.splice(currentUserIndex, 1);
            chatUsers.unshift(currentUser[0]);
          }
        }

        return chatUsers;
      },
      setActiveChatId: (chatId?: string) => {
        set(state => {
          if (!chatId) {
            return;
          }

          const chatUnit = useTableStore.getState().getTable(chatId);

          if (!chatUnit) {
            logger.warn('could not find chatUnit for chatId', chatId);
            return;
          }

          state.activeTableId = chatId;
          state.activeConversationIds = [];
          generateConversationMappings(state, chatId, chatUnit);
        });
      },
      setPendingConvoId: (conversationId: string) => {
        set(state => {
          if (state.activeConversationIds.includes(conversationId)) {
            state.pendingConvoId = conversationId;
            return state;
          }

          if (state.pendingConvoId && !state.pendingConvoHasMessages) {
            const pendingIndex = state.activeConversationIds.findIndex(
              cId => cId === state.pendingConvoId
            );
            if (pendingIndex !== -1) {
              state.activeConversationIds.splice(pendingIndex, 1);
            }
          }

          state.pendingConvoId = conversationId;
          state.pendingConvoHasMessages = false;
          state.activeConversationIds.unshift(conversationId);

          return state;
        });
      },
      setWebActiveConversationId: (id?: string) => {
        set(state => {
          state.webActiveConversationId = id;
        });
      },
      setSelectedMessage: (message: ChatMessage) => {
        set(state => {
          state.selectedMessage = message;
        });
      },
      clearSelectedMessage: () => {
        set(state => {
          state.selectedMessage = undefined;
        });
      },
      setQuery: (query: string) => {
        set(state => {
          state.query = query.toLowerCase();
        });
      },
      clearQuery: () => {
        set(state => {
          state.query = undefined;
        });
      },
      getOrCreateConversationId: (userIds: string[]) => {
        return new Promise<string>(resolve => {
          set(state => {
            const sortedUserIds = [...userIds].sort();

            let conversationId = state.userConversationIds.get(userIds);
            if (!conversationId) {
              conversationId = getConversationIdFromUserIds(sortedUserIds);

              state.conversationUserIds.set(conversationId, sortedUserIds);
              state.userConversationIds.set(sortedUserIds, conversationId);
            }

            resolve(conversationId);
          });
        });
      },
      getChatUnitUserIds: () => {
        return [...get().userIdIndexMap.keys()];
      },
      // cannot store pagerref directly so its stored like this
      setPagerRef: (ref: RefObject<PagerView> | null) => {
        set(state => {
          if (ref) {
            // random truncated string to store as id
            const id = Math.random().toString(36).substr(2, 9);
            pagerRefs.set(id, ref);
            state.pagerRefId = id;
          } else {
            state.pagerRefId = null;
          }
        });
      },
      getPagerRef: () => {
        const id = get().pagerRefId;
        return id ? pagerRefs.get(id) || null : null;
      },
      markPendingConvoHasMessages: () => {
        set(state => {
          state.pendingConvoHasMessages = true;
        });
      },
      handleArrowNavigation: (
        direction: 'left' | 'right',
        offset,
        pagerRef,
      ) => {
        let newIndex = offset.value;
        const activeConversationIds = get().activeConversationIds;
        const setWebActiveConversationId = get().setWebActiveConversationId;
        if (
          direction === 'right' &&
          newIndex < activeConversationIds.length - 1
        ) {
          newIndex += 1;
        } else if (direction === 'left' && newIndex > 0) {
          newIndex -= 1;
        }

        offset.value = newIndex;

        if (pagerRef.current) {
          pagerRef.current.setPageWithoutAnimation(newIndex);
        }

        setWebActiveConversationId(activeConversationIds[newIndex]);
      },
      setReplyToMessage: (message?: CustomMsg) => {
        set(state => {
          state.replyToMessage = message;
        });
      },

      clearReplyToMessage: () => {
        set(state => {
          state.replyToMessage = undefined;
        });
      },
    })),
  );

const ActiveConversationProvider = ({ children }: any) => {
  return (
    <Provider createStore={createActiveConversationStore}>{children}</Provider>
  );
};

export {
  ActiveConversationProvider,
  useActiveConversationStore,
  useActiveConversationStoreApi,
};

