import { ChatUnitSubType, ChatUnitType } from '@/API';
import { constants } from '@/constants';
import { createChatUnit } from '@/domain/chatUnit/functions/createChatUnit';
import { getChatUnitLimit } from '@/domain/chatUnit/functions/getChatUnitLimit';
import type { ContactInput } from '@/domain/chatUnit/functions/inviteContactsToDM';
import { inviteContactsToDM } from '@/domain/chatUnit/functions/inviteContactsToDM';
import LexicalSectionList from '@/domain/contact/components/directory/listView';
import { getMobileNumberForContact } from '@/domain/contact/functions/getMobileNumberForContact';
import type { Contact } from '@/domain/contact/state/useContactStore';
import ChoosePhoneNumberSheet from '@/domain/invites/components/ChoosePhoneNumberSheet';
import { InviteActionSheet } from '@/domain/invites/components/InviteActionSheet';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import { Colors } from '@/domain/theme/Colors';
import { useDefaultCallingCode } from '@/domain/user/hooks/useDefaultCallingCode';
import useUserStore from '@/domain/user/state/useUserStore';
import { useAppContacts } from '@/hooks/useAppContacts';
import useNavigation from '@/hooks/useNavigation';
import { eventBus } from '@/services/eventBus/eventBus';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { AppText, Icon, Spacer } from '@/ui/app/elements';
import { Greys } from '@/ui/common/colors';
import sleep from '@/utilities/helpers/sleep';
import generateUniqueId from '@/utilities/helpers/uuid';
import { isActiveMemberOfOrg } from '@/utilities/org/isActiveMemberOfOrg';
import { android, ios, web } from '@/utilities/platform';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  Alert,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import type { PhoneNumber } from 'react-native-contacts';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import SwitchWorkspaceModal from './SwitchWorkspaceModal';
import { UsersInputGroup } from './UsersInputGroup';

const GroupChat = () => {
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  4;
  const [modalVisible, setModalVisible] = useState(false);
  const [isAddOpen, setAddOpen] = useState(false);
  const [contacts, setContacts] = React.useState<ContactInput[]>([]);
  const [loading, setLoading] = React.useState(false);
  const defaultCountryCallingCode = useDefaultCallingCode();
  const [showPhoneNumbers, setShowPhoneNumbers] = useState(false);
  const [contactToSelect, setContactToSelect] = useState<Contact>();

  const toggleInviteActions = useCallback(() => {
    setAddOpen(toggle => !toggle);
  }, []);

  const { sectionedContacts, searchQuery, setSearchQuery } = useAppContacts();

  const { translate } = useTranslations();
  const { bottom } = useSafeAreaInsets();
  const { height } = useWindowDimensions();
  const { goBack, navigate, replaceRoute } = useNavigation();
  const directorySessionId = useMemo(() => generateUniqueId(), []);
  const remainingUserCapacity = constants.dmUserLimit - contacts.length - 1;
  const user = useUserStore(state => state.user);
  const organisations = useOrganisations();
  const { showPrompt } = usePromptStore();
  const orgList = useMemo(() => {
    if (organisations.length) {
      return organisations
        ?.filter(org => isActiveMemberOfOrg([org], org.id, user?.id))
        .map(org => ({
          id: org?.id!,
          image: org?.image!,
          name: org?.name!,
        }))
        .concat({
          id: 'personal',
          image: user?.avatar!,
          name: translate('personal_workspace'),
        });
    }
    return [
      {
        id: 'personal',
        image: user?.avatar!,
        name: translate('personal_workspace'),
      },
    ];
  }, [organisations, translate, user?.avatar, user?.id]);

  const [selectedOrganisation, setSelectedOrganisation] = useState(orgList[0]);
  const limit = getChatUnitLimit(ChatUnitType.QUICK_CHAT) - 1;
  const goToDirectory = useCallback(() => {
    navigate('Directory', {
      initialContacts: contacts,
      limit: limit,
      sessionId: directorySessionId,
      type: ChatUnitType.QUICK_CHAT,
      orgId:
        selectedOrganisation.id === 'personal'
          ? undefined
          : selectedOrganisation.id,
    });
  }, [
    contacts,
    directorySessionId,
    navigate,
    selectedOrganisation.id,
    translate,
  ]);

  const goToCreateInvite = useCallback(() => {
    navigate('CreateInvite', {
      type: ChatUnitType.QUICK_CHAT,
      sessionId: directorySessionId,
    });
  }, [navigate, directorySessionId]);

  const isValid = useCallback(() => {
    return contacts.length > 0;
  }, [contacts.length]);

  useEffect(() => {
    eventBus.on(
      'directorySubmitted',
      ({ contacts: selectedContacts, sessionId }) => {
        if (sessionId === directorySessionId) {
          setContacts(selectedContacts);
          goBack(); // return back to this screen
        }
      },
    );

    return () => {
      eventBus.off('directorySubmitted');
    };
  }, [directorySessionId, goBack]);

  useEffect(() => {
    eventBus.on(
      'inviteUserSubmitted',
      ({ contacts: selectedContacts, sessionId }) => {
        if (sessionId === directorySessionId) {
          setContacts([...contacts, ...selectedContacts]);
          goBack(); // return back to this screen
        }
      },
    );

    return () => {
      eventBus.off('inviteUserSubmitted');
    };
  }, [directorySessionId, goBack, contacts]);

  const generateSubject = () => {
    const yourName = user?.firstName || user?.username;
    if (contacts.length === 1) {
      return `${yourName} & ${contacts[0].firstName || contacts[0].username}`;
    }
    if (contacts.length === 2) {
      return `${yourName}, ${contacts[0].firstName || contacts[0].username} & ${contacts[1].firstName || contacts[1].username}`;
    }
    if (contacts.length === 3) {
      return `${yourName}, ${contacts[0].firstName || contacts[0].username}, ${contacts[1].firstName || contacts[1].username} & ${contacts[2].firstName || contacts[2].username}`;
    }
    return contacts.length > 3
      ? `${yourName}, ${contacts[0].firstName || contacts[0].username}, ${contacts[1].firstName || contacts[1].username} & ${contacts.length - 2} others`
      : '';
  };

  const onSubmit = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      setLoading(true);
      const startTimestamp = Date.now();
      const orgId =
        selectedOrganisation.id === 'personal'
          ? undefined
          : selectedOrganisation.id;

      const { chatUnit } = await createChatUnit({
        allowedUserIds: [user.id],
        title: subject || generateSubject(),
        type: ChatUnitType.QUICK_CHAT,
        subtype: ChatUnitSubType.GROUP_CHAT,
        user,
        organisationId: orgId,
        backgroundImage: 'whisper',
      });

      // invite each of the other users to the chat
      await inviteContactsToDM(chatUnit.id, contacts, user.id);

      const executionTime = Date.now() - startTimestamp;
      logger.info('CreateDM', {
        numContacts: contacts.length,
        executionTime: executionTime / 1000,
      });

      replaceRoute('ChatView', {
        chatUnitId: chatUnit.id,
        firstMessage: message?.trim(),
      }); // go to the newly created DM
    } catch (error) {
      logger.error('createDMError', error);
      showPrompt({
        title: translate('send_invite_error_prompt_title'),
        body: translate('send_invite_error_prompt_body'),
      });
    } finally {
      setLoading(false);
    }
  }, [
    contacts,
    replaceRoute,
    selectedOrganisation.id,
    showPrompt,
    subject,
    translate,
    user,
  ]);

  const handleContactSelect = useCallback(
    async (contact: Contact) => {
      const indexOfSelectContact = contacts.findIndex(
        existingSelectedContacts => contact.id === existingSelectedContacts.id,
      );
      if (indexOfSelectContact >= 0) {
        setContacts(prev => [
          ...prev.filter(contactItem => contactItem.id !== contact.id),
        ]);
        return;
      }
      if (limit === undefined || limit > contacts.length) {
        if (contact.phoneNumbers.length > 1) {
          setContactToSelect(contact);
          await sleep(constants.modalRaceConditionDelay);
          setShowPhoneNumbers(true);
        } else {
          const validMobileNumber = getMobileNumberForContact(
            contact,
            defaultCountryCallingCode,
          );

          if (!validMobileNumber) {
            Alert.alert(
              translate('unable_to_add_contact_alert_title', {
                name: contact.firstName,
              }),
              translate('unable_to_add_contact_alert_message') +
                '\n' +
                contact.phoneNumbers.map(p => p.number).join('\n'),
            );
            return;
          }
          const selectedContact = {
            ...contact,
            phoneNumbers: [
              {
                label: 'mobile',
                number: validMobileNumber,
              },
            ],
          };
          addSelectedContact(selectedContact);
        }
      }
    },
    [defaultCountryCallingCode, translate, limit, contacts],
  );

  const handlePhoneSelect = useCallback(
    (phone: PhoneNumber) => {
      setShowPhoneNumbers(false);
      const selectedContact = {
        ...contactToSelect!,
        phoneNumbers: [phone],
      };
      setContactToSelect(undefined);
      addSelectedContact(selectedContact);
    },
    [contactToSelect],
  );

  const addSelectedContact = (selectedContact: Contact) => {
    if (!user) {
      return;
    }
    setContacts(prev => {
      const indexOfSelectContact = prev.findIndex(
        existingSelectedContacts =>
          selectedContact.id === existingSelectedContacts.id,
      );
      if (indexOfSelectContact === -1) {
        return [...prev, selectedContact];
      }
      if (indexOfSelectContact >= 0) {
        return [...prev.filter(contact => selectedContact.id !== contact.id)];
      }

      return prev;
    });
  };

  const onSubmitEditing = useCallback(() => {
    if (searchQuery.length === 0 || sectionedContacts.length > 2) {
      return;
    }
    if (sectionedContacts.length === 2) {
      handleContactSelect(sectionedContacts[1] as Contact);
    }
  }, [searchQuery, sectionedContacts]);

  const switchModalConfirm = useCallback(
    (selectedOrganisationId?: string) => {
      if (selectedOrganisationId) {
        const selectedOrg = orgList.find(
          org => org.id === selectedOrganisationId,
        );
        if (selectedOrg) {
          setSelectedOrganisation(selectedOrg);
        }
      }
      setModalVisible(false);
    },
    [orgList],
  );

  return (
    <View
      style={[styles.container, { height: android ? height - 100 : '100%' }]}>
      <View style={styles.searchContainer}>
        <UsersInputGroup
          addHandler={toggleInviteActions}
          contacts={contacts}
          setContacts={setContacts}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onSubmitEditing={onSubmitEditing}
        />
      </View>
      {(android || ios) && <Spacer height={20} />}
      {(searchQuery.length === 0 ||
        (searchQuery.length > 0 && sectionedContacts.length === 0)) && (
        <>
          <ScrollView
            style={[styles.itemContainer, styles.subjectContainer]}
            keyboardShouldPersistTaps="handled">
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <Icon
                  fill={Colors.primaryLight}
                  iconKey="Tag"
                  size={18}
                  style={styles.icon}
                />
                <AppText style={styles.label} size={12} type="primary700">
                  {translate('Subject')}
                </AppText>
              </View>
              <AppText style={styles.optional} size={12} type="primary400">
                ({translate('optional')})
              </AppText>
            </View>
            <TextInput
              onChangeText={setSubject}
              placeholder={translate('add_a_subject_line')}
              placeholderTextColor={Colors.neutral50}
              style={styles.input}
              defaultValue={subject}
              maxLength={20}
            />
          </ScrollView>
          <ScrollView
            style={styles.itemContainer}
            keyboardShouldPersistTaps="handled">
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <Icon
                  fill={Colors.neutral0}
                  iconKey="Message"
                  size={18}
                  style={styles.icon}
                />
                <AppText style={styles.label} size={12} type="primary700">
                  {translate('Message')}
                </AppText>
              </View>
              <AppText style={styles.optional} size={12} type="primary400">
                ({translate('optional')})
              </AppText>
            </View>
            <TextInput
              onChangeText={setMessage}
              placeholder={translate('Write_a_message_to_send_instantly')}
              placeholderTextColor={Colors.neutral50}
              style={[styles.input, styles.messageInput]}
              value={message}
              multiline={true}
            />
          </ScrollView>
          <View
            style={[
              styles.sendContainer,
              { bottom: web ? 70 : ios ? bottom + 190 : 150 },
            ]}>
            <AppText size={10} style={styles.switchWorkspaceContainer}>
              This DM will be created in the{' '}
              <AppText size={10} type="primary700">
                {selectedOrganisation.name}
              </AppText>{' '}
              workspace{' '}
              <AppText
                size={10}
                type="primary700"
                style={styles.switchWorkspaceLink}
                onPress={() => setModalVisible(true)}>
                Switch workspace
              </AppText>
            </AppText>
            <TouchableOpacity
              style={[
                styles.sendButton,
                !isValid() ? { backgroundColor: Greys.shade400 } : {},
              ]}
              disabled={!isValid()}
              onPress={onSubmit}>
              {loading ? (
                <ActivityIndicator color={Colors.neutral0} size="small" />
              ) : (
                <>
                  <Icon
                    fill={Colors.neutral0}
                    iconKey="PaperPlane"
                    size={18}
                    style={styles.sendIcon}
                  />
                  <AppText style={styles.sendButtonText} size={17}>
                    {translate('send')}
                  </AppText>
                </>
              )}
            </TouchableOpacity>
          </View>
        </>
      )}

      <KeyboardAvoidingView
        behavior="padding"
        style={styles.flex}
        keyboardVerticalOffset={100}>
        {searchQuery.length > 0 && sectionedContacts.length > 0 && (
          <LexicalSectionList
            onContactPress={handleContactSelect}
            searchQuery={searchQuery}
            sections={sectionedContacts}
            selectedContacts={contacts}
          />
        )}
      </KeyboardAvoidingView>

      <SwitchWorkspaceModal
        visible={modalVisible}
        onClose={switchModalConfirm}
      />
      <InviteActionSheet
        goToCreateInvite={goToCreateInvite}
        goToCreateInvites={goToDirectory}
        isOpen={isAddOpen}
        setIsOpen={setAddOpen}
        disableCreateInvite={remainingUserCapacity === 0}
      />
      <ChoosePhoneNumberSheet
        contact={contactToSelect}
        onPhoneSelect={handlePhoneSelect}
        open={showPhoneNumbers}
        setOpen={setShowPhoneNumbers}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    minHeight: '100%',
  },
  itemContainer: {
    borderTopColor: Colors.neutral20,
    borderTopWidth: 1,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  searchContainer: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  searchHeader: {
    paddingHorizontal: 10,
  },
  icon: {
    marginRight: 10,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerLeft: { flex: 1, flexDirection: 'row', alignItems: 'center' },
  input: {
    color: Colors.neutral80,
    marginTop: 5,
    fontSize: 14,
    padding: 0,
    fontWeight: '400',
    height: 20,
    textAlignVertical: 'top',
  },
  messageInput: {
    height: 220,
    borderWidth: 1,
    borderColor: Colors.neutral20,
    borderRadius: 12,
    marginTop: 10,
    paddingTop: 15,
    paddingHorizontal: 20,
  },
  label: {
    color: Colors.neutral70,
    textTransform: 'uppercase',
  },
  optional: {
    color: Colors.neutral70,
    textTransform: 'capitalize',
  },
  sendContainer: {
    borderTopColor: Colors.neutral20,
    borderTopWidth: 1,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 1,
  },
  sendButton: {
    height: 40,
    backgroundColor: Colors.primaryLight,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    marginHorizontal: 20,
    marginTop: 20,
    paddingHorizontal: 30,
  },
  sendIcon: {
    marginRight: 10,
  },
  sendButtonText: {
    color: Colors.neutral0,
    fontWeight: '700',
  },
  switchWorkspaceContainer: {
    marginHorizontal: 20,
    marginTop: 10,
  },
  switchWorkspaceLink: {
    color: Colors.primaryLight,
  },
  subjectContainer: { minHeight: 65, maxHeight: 65 },
});

export default GroupChat;
