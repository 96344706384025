/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateActivity = /* GraphQL */ `subscription OnCreateActivity(
  $filter: ModelSubscriptionActivityFilterInput
  $owner: String
) {
  onCreateActivity(filter: $filter, owner: $owner) {
    PK
    SK
    owner
    userId
    model
    type
    relation
    payload
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateActivitySubscriptionVariables,
  APITypes.OnCreateActivitySubscription
>;
export const onUpdateActivity = /* GraphQL */ `subscription OnUpdateActivity(
  $filter: ModelSubscriptionActivityFilterInput
  $owner: String
) {
  onUpdateActivity(filter: $filter, owner: $owner) {
    PK
    SK
    owner
    userId
    model
    type
    relation
    payload
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateActivitySubscriptionVariables,
  APITypes.OnUpdateActivitySubscription
>;
export const onDeleteActivity = /* GraphQL */ `subscription OnDeleteActivity(
  $filter: ModelSubscriptionActivityFilterInput
  $owner: String
) {
  onDeleteActivity(filter: $filter, owner: $owner) {
    PK
    SK
    owner
    userId
    model
    type
    relation
    payload
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteActivitySubscriptionVariables,
  APITypes.OnDeleteActivitySubscription
>;
export const onCreateChatUnit = /* GraphQL */ `subscription OnCreateChatUnit(
  $filter: ModelSubscriptionChatUnitFilterInput
  $owner: String
) {
  onCreateChatUnit(filter: $filter, owner: $owner) {
    id
    title
    colorId
    backgroundImage
    description
    url
    chatUnitUsers {
      items {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          organisationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    allowedUserIds
    type
    subtype
    allConversationId
    organisationId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChatUnitSubscriptionVariables,
  APITypes.OnCreateChatUnitSubscription
>;
export const onUpdateChatUnit = /* GraphQL */ `subscription OnUpdateChatUnit(
  $filter: ModelSubscriptionChatUnitFilterInput
  $owner: String
) {
  onUpdateChatUnit(filter: $filter, owner: $owner) {
    id
    title
    colorId
    backgroundImage
    description
    url
    chatUnitUsers {
      items {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          organisationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    allowedUserIds
    type
    subtype
    allConversationId
    organisationId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChatUnitSubscriptionVariables,
  APITypes.OnUpdateChatUnitSubscription
>;
export const onDeleteChatUnit = /* GraphQL */ `subscription OnDeleteChatUnit(
  $filter: ModelSubscriptionChatUnitFilterInput
  $owner: String
) {
  onDeleteChatUnit(filter: $filter, owner: $owner) {
    id
    title
    colorId
    backgroundImage
    description
    url
    chatUnitUsers {
      items {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          organisationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    allowedUserIds
    type
    subtype
    allConversationId
    organisationId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChatUnitSubscriptionVariables,
  APITypes.OnDeleteChatUnitSubscription
>;
export const onCreateInvite = /* GraphQL */ `subscription OnCreateInvite(
  $filter: ModelSubscriptionInviteFilterInput
  $owner: String
) {
  onCreateInvite(filter: $filter, owner: $owner) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInviteSubscriptionVariables,
  APITypes.OnCreateInviteSubscription
>;
export const onUpdateInvite = /* GraphQL */ `subscription OnUpdateInvite(
  $filter: ModelSubscriptionInviteFilterInput
  $owner: String
) {
  onUpdateInvite(filter: $filter, owner: $owner) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInviteSubscriptionVariables,
  APITypes.OnUpdateInviteSubscription
>;
export const onDeleteInvite = /* GraphQL */ `subscription OnDeleteInvite(
  $filter: ModelSubscriptionInviteFilterInput
  $owner: String
) {
  onDeleteInvite(filter: $filter, owner: $owner) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInviteSubscriptionVariables,
  APITypes.OnDeleteInviteSubscription
>;
export const onCreateConversation = /* GraphQL */ `subscription OnCreateConversation(
  $filter: ModelSubscriptionConversationFilterInput
  $owner: String
) {
  onCreateConversation(filter: $filter, owner: $owner) {
    conversationId
    chatUnitId
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      organisationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    chatUnitUserIds
    allowedUserIds
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConversationSubscriptionVariables,
  APITypes.OnCreateConversationSubscription
>;
export const onUpdateConversation = /* GraphQL */ `subscription OnUpdateConversation(
  $filter: ModelSubscriptionConversationFilterInput
  $owner: String
) {
  onUpdateConversation(filter: $filter, owner: $owner) {
    conversationId
    chatUnitId
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      organisationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    chatUnitUserIds
    allowedUserIds
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConversationSubscriptionVariables,
  APITypes.OnUpdateConversationSubscription
>;
export const onDeleteConversation = /* GraphQL */ `subscription OnDeleteConversation(
  $filter: ModelSubscriptionConversationFilterInput
  $owner: String
) {
  onDeleteConversation(filter: $filter, owner: $owner) {
    conversationId
    chatUnitId
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      organisationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    chatUnitUserIds
    allowedUserIds
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConversationSubscriptionVariables,
  APITypes.OnDeleteConversationSubscription
>;
export const onCreateMessage = /* GraphQL */ `subscription OnCreateMessage(
  $filter: ModelSubscriptionMessageFilterInput
  $owner: String
) {
  onCreateMessage(filter: $filter, owner: $owner) {
    id
    conversationId
    userId
    chatUnitId
    text
    image
    chatUnitUserId
    createdAt
    allowedUserIds
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMessageSubscriptionVariables,
  APITypes.OnCreateMessageSubscription
>;
export const onUpdateMessage = /* GraphQL */ `subscription OnUpdateMessage(
  $filter: ModelSubscriptionMessageFilterInput
  $owner: String
) {
  onUpdateMessage(filter: $filter, owner: $owner) {
    id
    conversationId
    userId
    chatUnitId
    text
    image
    chatUnitUserId
    createdAt
    allowedUserIds
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMessageSubscriptionVariables,
  APITypes.OnUpdateMessageSubscription
>;
export const onDeleteMessage = /* GraphQL */ `subscription OnDeleteMessage(
  $filter: ModelSubscriptionMessageFilterInput
  $owner: String
) {
  onDeleteMessage(filter: $filter, owner: $owner) {
    id
    conversationId
    userId
    chatUnitId
    text
    image
    chatUnitUserId
    createdAt
    allowedUserIds
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMessageSubscriptionVariables,
  APITypes.OnDeleteMessageSubscription
>;
export const onCreateMessageV2 = /* GraphQL */ `subscription OnCreateMessageV2(
  $filter: ModelSubscriptionMessageV2FilterInput
  $owner: String
) {
  onCreateMessageV2(filter: $filter, owner: $owner) {
    id
    userId
    SK
    allowedUserIds
    authorId
    chatUnitUserId
    chatUnitId
    chatUnitIdConversationId
    conversationId
    createdAt
    image
    messageId
    owner
    status
    text
    source
    metaData
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMessageV2SubscriptionVariables,
  APITypes.OnCreateMessageV2Subscription
>;
export const onUpdateMessageV2 = /* GraphQL */ `subscription OnUpdateMessageV2(
  $filter: ModelSubscriptionMessageV2FilterInput
  $owner: String
) {
  onUpdateMessageV2(filter: $filter, owner: $owner) {
    id
    userId
    SK
    allowedUserIds
    authorId
    chatUnitUserId
    chatUnitId
    chatUnitIdConversationId
    conversationId
    createdAt
    image
    messageId
    owner
    status
    text
    source
    metaData
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMessageV2SubscriptionVariables,
  APITypes.OnUpdateMessageV2Subscription
>;
export const onDeleteMessageV2 = /* GraphQL */ `subscription OnDeleteMessageV2(
  $filter: ModelSubscriptionMessageV2FilterInput
  $owner: String
) {
  onDeleteMessageV2(filter: $filter, owner: $owner) {
    id
    userId
    SK
    allowedUserIds
    authorId
    chatUnitUserId
    chatUnitId
    chatUnitIdConversationId
    conversationId
    createdAt
    image
    messageId
    owner
    status
    text
    source
    metaData
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMessageV2SubscriptionVariables,
  APITypes.OnDeleteMessageV2Subscription
>;
export const onCreateOrganisation = /* GraphQL */ `subscription OnCreateOrganisation(
  $filter: ModelSubscriptionOrganisationFilterInput
  $owner: String
) {
  onCreateOrganisation(filter: $filter, owner: $owner) {
    id
    name
    billingId
    users
    members {
      userId
      role
      status
      name
      phoneNumber
      avatar
      __typename
    }
    allowedUserIds
    plan
    image
    usage {
      guests
      tables
      rooms
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrganisationSubscriptionVariables,
  APITypes.OnCreateOrganisationSubscription
>;
export const onUpdateOrganisation = /* GraphQL */ `subscription OnUpdateOrganisation(
  $filter: ModelSubscriptionOrganisationFilterInput
  $owner: String
) {
  onUpdateOrganisation(filter: $filter, owner: $owner) {
    id
    name
    billingId
    users
    members {
      userId
      role
      status
      name
      phoneNumber
      avatar
      __typename
    }
    allowedUserIds
    plan
    image
    usage {
      guests
      tables
      rooms
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrganisationSubscriptionVariables,
  APITypes.OnUpdateOrganisationSubscription
>;
export const onDeleteOrganisation = /* GraphQL */ `subscription OnDeleteOrganisation(
  $filter: ModelSubscriptionOrganisationFilterInput
  $owner: String
) {
  onDeleteOrganisation(filter: $filter, owner: $owner) {
    id
    name
    billingId
    users
    members {
      userId
      role
      status
      name
      phoneNumber
      avatar
      __typename
    }
    allowedUserIds
    plan
    image
    usage {
      guests
      tables
      rooms
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrganisationSubscriptionVariables,
  APITypes.OnDeleteOrganisationSubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onCreateUser(filter: $filter, owner: $owner) {
    id
    name
    firstName
    lastName
    username
    avatar
    email
    phoneNumber
    gender
    birthday
    pronouns
    marketingOptIn
    organisations
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onUpdateUser(filter: $filter, owner: $owner) {
    id
    name
    firstName
    lastName
    username
    avatar
    email
    phoneNumber
    gender
    birthday
    pronouns
    marketingOptIn
    organisations
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onDeleteUser(filter: $filter, owner: $owner) {
    id
    name
    firstName
    lastName
    username
    avatar
    email
    phoneNumber
    gender
    birthday
    pronouns
    marketingOptIn
    organisations
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateChatUnitUser = /* GraphQL */ `subscription OnCreateChatUnitUser(
  $filter: ModelSubscriptionChatUnitUserFilterInput
  $owner: String
) {
  onCreateChatUnitUser(filter: $filter, owner: $owner) {
    id
    chatUnitId
    userId
    username
    phoneNumber
    avatar
    status
    allowedUserIds
    conversationIds
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      organisationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    firstName
    lastName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    chatUnitChatUnitUsersId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChatUnitUserSubscriptionVariables,
  APITypes.OnCreateChatUnitUserSubscription
>;
export const onUpdateChatUnitUser = /* GraphQL */ `subscription OnUpdateChatUnitUser(
  $filter: ModelSubscriptionChatUnitUserFilterInput
  $owner: String
) {
  onUpdateChatUnitUser(filter: $filter, owner: $owner) {
    id
    chatUnitId
    userId
    username
    phoneNumber
    avatar
    status
    allowedUserIds
    conversationIds
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      organisationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    firstName
    lastName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    chatUnitChatUnitUsersId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChatUnitUserSubscriptionVariables,
  APITypes.OnUpdateChatUnitUserSubscription
>;
export const onDeleteChatUnitUser = /* GraphQL */ `subscription OnDeleteChatUnitUser(
  $filter: ModelSubscriptionChatUnitUserFilterInput
  $owner: String
) {
  onDeleteChatUnitUser(filter: $filter, owner: $owner) {
    id
    chatUnitId
    userId
    username
    phoneNumber
    avatar
    status
    allowedUserIds
    conversationIds
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      organisationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    firstName
    lastName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    chatUnitChatUnitUsersId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChatUnitUserSubscriptionVariables,
  APITypes.OnDeleteChatUnitUserSubscription
>;
