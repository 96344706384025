import type React from 'react';
import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  type LayoutChangeEvent,
  type NativeSyntheticEvent,
  Platform,
  Pressable,
  type StyleProp,
  StyleSheet,
  Text,
  type TextLayoutEventData,
  type TextProps,
  type TextStyle,
} from 'react-native';

import { useTheme } from '@/domain/theme';
import type { Colors } from '@/domain/theme/Colors';
import { Fonts } from '@/ui/common/styles';
import type { Color } from '@/ui/common/types/color';

export interface AppTextProps extends TextProps {
  /** Color of the text */
  color?: Color | Colors;
  /**
   * Specifies whether font should be scaled down
   * automatically to fit given style constraints.
   * This results in font size being reduced
   * until it fits the constraints of the container.
   */
  shrinkFit?: TextProps['adjustsFontSizeToFit'];
  /** Font size in density pixels (dp) */
  size?: number;
  style?: StyleProp<TextStyle>;
  /** Specifies text alignment in the container */
  textAlign?: TextStyle['textAlign'];
  /** Font type */
  type?: keyof typeof Fonts;
  /** Specifies whether text should be underlined */
  underline?: boolean;
  /** Collapsible can truncate AppText and make it collapsible and expandable if true.
   * maxLines specifies maximum lines it can show before truncating it.
   */
  collapsible?: boolean;
  maxLines?: number;
  /** Passed to show message options sheet in Bubble Message */
  onLongPress?: () => void;
  themeColor?: string;
}

const CollapsibleText: React.FC<PropsWithChildren<AppTextProps>> = ({
  children,
  color: inputColor,
  shrinkFit = false,
  size: fontSize = 16,
  style,
  textAlign = undefined,
  // Convenience shortcut styles
  type = 'primary400',
  underline = false,
  collapsible = false,
  maxLines = 6,
  onLongPress,
  themeColor,
  ...otherProps
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsible);
  const [isTruncated, setIsTruncated] = useState(false);
  const [textMeasured, setTextMeasured] = useState(false);
  const [textMeasuredMobile, setTextMeasuredMobile] = useState(false);

  const fontFamily = getFontFamilyByWeight(type);

  const {
    theme: {
      ui: {
        text: { defaultColor },
      },
    },
  } = useTheme();

  const color = inputColor ?? defaultColor;

  const lineHeight = fontSize * 1.2; // Consistent line height

  const textStyle = StyleSheet.create({
    text: {
      color,
      fontFamily,
      fontSize,
      lineHeight, // Apply lineHeight to text style
      ...(underline && {
        textDecorationColor: color,
        textDecorationLine: 'underline',
      }),
      ...(textAlign && {
        textAlign,
      }),
    },
  });

  const handleHiddenTextLayout = useCallback(
    (e: NativeSyntheticEvent<TextLayoutEventData>) => {
      if (collapsible && Platform.OS !== 'web') {
        const totalLines = e.nativeEvent.lines.length;
        setIsTruncated(totalLines > maxLines);
        setTextMeasuredMobile(true);
      }
    },
    [collapsible, maxLines],
  );

  const handleViewLayout = useCallback(
    (event: LayoutChangeEvent) => {
      if (collapsible && Platform.OS === 'web') {
        const { height } = event.nativeEvent.layout;
        const numberOfLines = Math.round(height / lineHeight);
        setIsTruncated(numberOfLines > maxLines);
        setTextMeasured(true);
      }
    },
    [collapsible, maxLines, lineHeight],
  );

  const toggleCollapse = useCallback(() => {
    if (collapsible) {
      setIsCollapsed(prev => !prev);
    }
  }, [collapsible]);

  const handleLongPress = useCallback(() => {
    onLongPress?.();
  }, [onLongPress]);

  // Handle window resize events on web
  useEffect(() => {
    if (collapsible && Platform.OS === 'web') {
      const handleResize = () => {
        setTextMeasured(false); // Force re-measurement
      };

      //@ts-ignore web specific code
      window.addEventListener('resize', handleResize);

      return () => {
        //@ts-ignore web specific code
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [collapsible]);

  return (
    <>
      {/* Hidden Text for measuring on web */}
      {collapsible && Platform.OS === 'web' && !textMeasured && (
        <Text
          style={[
            textStyle.text,
            style,
            { position: 'absolute', opacity: 0, zIndex: -1 },
          ]}
          onLayout={handleViewLayout}
          {...otherProps}>
          {children}
        </Text>
      )}
      {/* Hidden Text for measuring on mobile */}
      {collapsible && Platform.OS !== 'web' && !textMeasuredMobile && (
        <Text
          style={[
            textStyle.text,
            style,
            { position: 'absolute', opacity: 0, zIndex: -1 },
          ]}
          onTextLayout={handleHiddenTextLayout}
          {...otherProps}
        >
          {children}
        </Text>
      )}
      {/* Visible Text */}
      <Pressable onPress={toggleCollapse} onLongPress={handleLongPress}>
        <Text
          adjustsFontSizeToFit={shrinkFit}
          allowFontScaling={false}
          maxFontSizeMultiplier={1}
          style={[textStyle.text, style]}
          numberOfLines={isCollapsed ? maxLines : undefined}
          {...otherProps}
        >
          {children}
        </Text>
        {isTruncated && (
          <Text
            style={[
              textStyle.text,
              { color: themeColor, marginTop: 5, fontWeight: '500' },
            ]}>
            {isCollapsed ? 'Tap for more' : 'Tap for less'}
          </Text>
        )}
      </Pressable>
    </>
  );
};

const getFontFamilyByWeight = (weight: keyof typeof Fonts) => {
  const font = Fonts[weight];
  return font;
};

export default CollapsibleText;
